<template>
    <div class="contents" style="height: calc(100vh - 100px);">
        <!-- <div class="container mb-3">
            <div class="row">
                <div class="col-lg-12">

                    <div class="d-flex justify-content-between align-items-center">
                        <h4 class="text-capitalize breadcrumb-title">Chat</h4>
                        <div>
                            <button :class="getCategoryButtonClass('')" style="font-size: 14px; margin-left:5px" v-on:click="changeChatCategory('')">Semua</button>
                            <button :class="getCategoryButtonClass(cat.name)" style="font-size: 14px; margin-left:5px" v-for="(cat, idx) in category" v-bind:key="idx" v-on:click="changeChatCategory(cat.name)">{{ cat.name }}</button>
                        <button class="btn btn-success btn-sm" style="font-size: 14px; margin-left:5px" @click="tampilCekOngkir" >CEK ONGKIR</button>
                        </div>
                    </div>
                    <div>
                    </div>

                </div>
            </div>
        </div> -->
        <div class="container h-100">
            <div class="chat-area d-flex mb-40 h-100" style="justify-content:normal !important">
                <div class="mb-lg-0 mb-40 chat-sidebar" style="min-width: 30%; position: relative; max-height: 950px">
                    <div class="sidebar-group left-sidebar1 chat_sidebar h-100">
                        <div id="chat" class="left-sidebar-wrap bg-white radius-xl active h-100">
                            <div class="chat-wrapper h-100">
                                <div class="search-tab">
                                    <ul class="nav ap-tab-main border-bottom text-capitalize" id="ueberTab"
                                        role="tablist">
                                        <!-- <li class="nav-item mr-0">
                                            <a class="nav-link" :class="{ active : tabActive == 'A' }" v-on:click="changeTab('A')">
                                                Pending Chat
                                                <span class="badge badge-primary">{{ countChatLists($store.state.customer_service.pending_chat_lists) }}</span>
                                            </a>
                                        </li>
                                        <li class="nav-item mr-0">
                                            <a class="nav-link" :class="{ active : tabActive == 'B' }" v-on:click="changeTab('B')">
                                                Active Chat
                                                <span class="badge badge-primary">{{ countChatLists($store.state.customer_service.active_chat_lists) }}</span>
                                            </a>
                                        </li> -->
                                        <!-- <li class="nav-item mr-0">
                                            <a class="nav-link" id="third-tab" data-target="#panel_b_thrid" data-secondary="#panel_a_third" data-toggle="tab" href="#third" role="tab" aria-controls="third-tab" aria-selected="false">all
                                                contact</a>
                                        </li> -->
                                    </ul>
                                    <ul class="nav nav-pills m-t-30 m-b-30">
                                        <li class=" nav-item w-50 text-center"> <a v-on:click="changeTab('A')" class="nav-link"
                                                :class="{ active: tabActive == 'A' }" data-toggle="tab"
                                                aria-expanded="false">Pending Chat</a> </li>
                                        <li class="nav-item w-50 text-center"> <a v-on:click="changeTab('B')" class="nav-link"
                                                :class="{ active: tabActive == 'B' }" data-toggle="tab"
                                                aria-expanded="false">Active Chat</a> </li>
                                    </ul>
                                </div>
                                <div class="search-body">
                                    <div class="tab-content" v-if="tabActive == 'A'">
                                        <div class="tab-pane fade show active mt-3" id="panel_a_first" role="tabpanel"
                                            aria-labelledby="first-tab" style="max-height: 875px; overflow-y: auto;">
                                            <ul class="user-list" style="padding: 10px" v-if="!isChatListLoading">
                                                <div v-for="(chatList, idx) in $store.state.customer_service.pending_chat_lists" v-bind:key="idx">
                                                    <li class="user-list-item" style="list-style: none;" :class="isSelected.includes(chatList.number) && 'chatactive'">
                                                        <div v-on:click="openChat(chatList.name, chatList.number, false)" class="user-list-item__wrapper d-flex"
                                                            style="padding: 10px; gap: 1rem">
                                                            <div class="avatar avatar-circle ml-0">
                                                                <img src="@/assets/img/ellipse1.png" class="rounded-circle wh-46 d-flex bg-opacity-primary" alt="image">
                                                                <div class="badge-direction-bottom">
                                                                    <span class="chat-badge-dot avatar-online"></span>
                                                                </div>
                                                            </div>
                                                            <div class="users-list-body" style="flex-grow: 1;">
                                                                <div class="users-list-body-title">
                                                                    <h6>{{ chatList.name }}</h6>
                                                                    <h6>{{ chatList.number }}</h6>
                                                                    <div class="text-limit" data-maxlength="10">
                                                                        <p class="mb-0">
                                                                            <span>{{ trimString(chatList.last_chat.text, 25) }}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="last-chat-time unread">
                                                                    <small>{{ formatDateRelative(chatList.updated_at) }}</small>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex justify-content-between align-items-center" v-if="chatList.notification">
                                                                <div class="total-message mt-1 d-flex justify-content-end" style="height: fit-content;">
                                                                    <span class="badge badge-success">{{ chatList.notification }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row" style="padding: 10px">
                                                            <div class="col-md-12">
                                                                <button v-on:click="receiveChat(chatList.number)" class="btn btn-success btn-block">Terima</button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </div>
                                            </ul>
                                            <ul v-else>
                                                <br>
                                                <center>
                                                    <div class="spinner-border spinner" role="status"></div>
                                                </center>
                                            </ul>
                                        </div>

                                    </div>
                                    <div class="tab-content" v-else-if="tabActive == 'B'">
                                        <div class="tab-pane fade show active mt-3 h-100" id="panel_a_first" role="tabpanel"
                                            aria-labelledby="first-tab" style="max-height: 875px; overflow-y: auto;">
                                            <ul class="user-list" style="padding: 10px" v-if="!isChatListLoading">
                                                <div v-for="(chatList, idx) in $store.state.customer_service.active_chat_lists"
                                                    v-bind:key="idx">
                                                    <li class="user-list-item" style="list-style: none;"
                                                        :class="isSelected.includes(chatList.number) && 'chatactive'">
                                                        <div v-on:click="openChat(chatList.name, chatList.number, true)"
                                                            class="user-list-item__wrapper d-flex"
                                                            style="padding: 10px; gap: 1rem">
                                                            <div class="avatar avatar-circle ml-0">
                                                                <img src="@/assets/img/ellipse1.png"
                                                                    class="rounded-circle wh-46 d-flex bg-opacity-primary"
                                                                    alt="image">
                                                                <div class="badge-direction-bottom">
                                                                    <span class="chat-badge-dot avatar-online"></span>
                                                                </div>
                                                            </div>
                                                            <div class="users-list-body" style="flex-grow: 1;">
                                                                <div class="users-list-body-title">
                                                                    <h6>{{ chatList.name }}</h6>
                                                                    <h6>{{ chatList.number }}</h6>
                                                                    <div class="text-limit" data-maxlength="10">
                                                                        <p class="mb-0">
                                                                            <span>{{ trimString(chatList.last_chat.text,
                                                                                25) }}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="last-chat-time unread">
                                                                    <small>{{ formatDateRelative(chatList.last_update)
                                                                        }}</small>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex  justify-content-between align-items-center"
                                                                v-if="chatList.notification">
                                                                <div class="total-message mt-1 d-flex justify-content-end"
                                                                    style="height: fit-content;">
                                                                    <span class="badge badge-success">{{
                                                                        chatList.notification }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </div>

                                            </ul>
                                            <ul v-else>
                                                <br>
                                                <center>
                                                    <div class="spinner-border spinner" role="status"></div>
                                                </center>
                                            </ul>
                                        </div>
                                    </div>
                                </div><!-- Ends: .search-body -->
                            </div><!-- Ends: .chat-wrapper -->
                        </div>
                    </div><!-- Ends: .sidebar group -->
                </div><!-- Ends: .mb-lg-0 -->
                <div class="tab-content" style="width: 100% !important;position: relative; padding-left: 1.5rem; max-height: 950px"
                    id="ueberTabB">
                    <div class="tab-pane fade  show active h-100" id="panel_b_first" role="tabpanel"
                        aria-labelledby="first-tab">
                        <div v-if="isChatLoading">
                            <div class="chat-body bg-white radius-xl">
                                <div class="chat-header">
                                    <div class="media chat-name align-items-center">
                                        <div class="media-body align-self-center ">
                                            <h5 class=" mb-0 fw-500">Whacenter Web Whatsapp</h5>
                                        </div>
                                    </div>
                                    <!-- Chat Options -->
                                    <ul class="nav flex-nowrap">
                                        <li class="nav-item list-inline-item mr-0">
                                            <div class="dropdown">
                                                <a href="#" role="button" title="Details" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span data-feather="more-vertical"></span>
                                                </a>

                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a class="dropdown-item align-items-center d-flex" href="#"
                                                        data-chat-info-toggle="">
                                                        <!-- Default :: Inline SVG -->
                                                        <span data-feather="users"></span>
                                                        <span>Create new group</span>
                                                    </a>

                                                    <a class="dropdown-item align-items-center d-flex" href="#">
                                                        <!-- Default :: Inline SVG -->
                                                        <span data-feather="trash-2"></span>

                                                        <span>Delete conversation</span>
                                                    </a>
                                                    <a class="dropdown-item align-items-center d-flex" href="#">
                                                        <!-- Default :: Inline SVG -->
                                                        <span data-feather="x-octagon"></span>

                                                        <span>Block & report</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div class="chat-box p-xl-30 pl-lg-20 pr-lg-0">
                                    <center>
                                        <div class="spinner-border spinner" role="status"></div>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="currentChat" class="chat h-100">
                            <div class="chat-body bg-white radius-xl h-100">
                                <div class="chat-header d-flex justify-content-between align-content-center">
                                    <div class="media chat-name align-items-center">
                                        <div class="media-body align-self-center ">
                                            <h5 class=" mb-0 fw-500 mb-2">{{ currentChat.name }}</h5>
                                            <div class="media-body__content d-flex align-items-center">
                                                <!-- <span class="badge-dot dot-success mr-1"></span> -->
                                                <small class="d-flex color-light fs-12 text-capitalize">
                                                    {{ currentChat.number }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Chat Options -->
                                    <ul class="nav flex-nowrap">
                                        <li style="margin-left:10px" class="nav-item list-inline-item mr-0">
                                            <button v-if="isSendable" v-on:click="closeCurrentSession"
                                                class="btn btn-danger float-right">Tutup Session</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="chat-box p-xl-30 pl-lg-20 pr-lg-0" style="" ref="chatBox"
                                    :style="`overflow: auto;height: calc(${isSendable ? '70vh' : '80vh'});`">
                                    <!-- Start: Incomming -->
                                    <ul class="chat-list" v-for="(chat, idx) in $store.state.customer_service.chats"
                                        :key="idx">
                                        <li class="flex-1 incoming-chat chat-item" v-if="chat.is_me === 0">
                                            <div class="chat-img">
                                                <img src="@/assets/img/author/1.jpg" alt="user">
                                            </div>
                                            <div class="chat-content">
                                                <div class="chat-text-box__content">
                                                    <div class="chat-text-box__title" v-on:mouseover="hoverChat(idx)"
                                                        v-on:mouseleave="unhoverChat()">
                                                        <h6 class="fs-14">{{ currentChat.number }}</h6>
                                                        <div class="box bg-light-info" v-if="chat.message">{{ chat.message }}</div>
                                                        <div class="box bg-light-info" v-if="chat.media" style="width: 50%; display: flex; justify-content: center; align-items: center; overflow: hidden">
                                                            <img :src="chat.media" style="flex-shrink: 0; width: 100%;" :alt="`[Image]`">
                                                             <!-- <img :src="`https://asset-2.tstatic.net/tribunnews/foto/bank/images/poster-stay-at-home-cobvid-19-di-rumah-aja.jpg`" style="flex-shrink: 0; width: 100%;" :alt="`[Image]`"> -->
                                                        </div>
                                                        <!-- <div v-if="getHoverChat(idx)">
                                                            <button v-on:click="insertOrder(idx)" class="btn btn-sm btn-outline-success">INSERT ORDER</button>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="chat-time">{{ formatDateDetail(chat.created_at) }}</div>
                                        </li>
                                        <li class="flex-1 incoming-chat chat-item"
                                            style="text-align: end;"
                                            v-else>
                                            <!-- <div class="chat-img">
                                                <img src="@/assets/img/author/1.jpg" alt="user">
                                            </div> -->
                                            <div class="chat-content">
                                                <div class="chat-text-box__content box">
                                                    <div class="chat-text-box__title" v-on:mouseover="hoverChat(idx)"
                                                        v-on:mouseleave="unhoverChat()">
                                                        <div class="box box bg-light-inverse">{{ chat.message }}</div>
                                                        <div class="box bg-light-info" v-if="chat.media" style="width: 50%;">
                                                            <img :src="chat.media" style="flex-shrink: 0; width: 100%;" :alt="`[Image]`">
                                                             <!-- <img :src="`https://asset-2.tstatic.net/tribunnews/foto/bank/images/poster-stay-at-home-cobvid-19-di-rumah-aja.jpg`" style="flex-shrink: 0; width: 100%;" :alt="`[Image]`"> -->
                                                        </div>
                                                        <!-- <div v-if="getHoverChat(idx)">
                                                            <button v-on:click="insertOrder(idx)" class="btn btn-sm btn-outline-success">INSERT ORDER</button>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="chat-time">{{ formatDateDetail(chat.created_at) }}</div>
                                        </li>
                                        <!-- <div class="flex-1 justify-content-end d-flex outgoing-chat mt-20" v-else>
                                            <div class="chat-text-box">
                                                <div class="media ">
                                                    <div class="media-body">
                                                        <div class="chat-text-box__content">
                                                            <div class="chat-text-box__title d-flex align-items-center justify-content-end mb-2">
                                                                <span class="chat-text-box__time fs-12 color-light fw-400">{{ formatDateDetail(chat.created_at) }}</span>
                                                            </div>
                                                            <div class="d-flex align-items-center justify-content-end">
                                                                <div class="chat-text-box__other d-flex">
                                                                    <div class="px-15">
    
                                                                        <div class="dropdown dropdown-click">
                                                                            <button class="btn-link border-0 bg-transparent p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                <span data-feather="more-horizontal"></span>
                                                                            </button>
                                                                            <div class="dropdown-default dropdown-bottomRight dropdown-menu-right dropdown-menu" style="">
                                                                                <a class="dropdown-item" href="#">Copy</a>
                                                                                <a class="dropdown-item" href="#">Quote</a>
                                                                                <a class="dropdown-item" href="#">Forward</a>
                                                                                <a class="dropdown-item" href="#">Report</a>
                                                                                <a class="dropdown-item" href="#">remove</a>
                                                                            </div>
                                                                        </div>
    
                                                                    </div>
                                                                </div>
                                                                <div class="chat-text-box__subtitle p-20 bg-deep">
                                                                    <span class="color-gray" style="white-space: pre-line">{{ chat.text }}</span>
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </ul>
                                </div>

                                <div class="chat-footer px-xl-30 px-lg-20 pb-lg-30 pt-1" v-if="isSendable">
                                    <div v-if="autotext.length > 0" class="chat-type-text search-item"
                                        :style="{ 'bottom': bottomCSS }">
                                        <div v-for="(value, index) in autotext" v-bind:key="index"
                                            class="pt-0 outline-0 pb-0 pr-0 pl-0 d-flex align-items-center"
                                            style="cursor: pointer;" v-on:click="message = value">
                                            <div
                                                class="d-flex justify-content-between align-items-center w-100 flex-wrap">
                                                <div class="flex-1 d-flex align-items-center chat-type-dropdown ml-0">
                                                    {{ value }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-type-text">
                                        <CustomText v-model.lazy="message" @updateFile="updateFile"></CustomText>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="chat">
                            <div class="chat-body bg-white radius-xl">
                                <div class="chat-header">
                                    <div class="media chat-name align-items-center">
                                        <div class="media-body align-self-center ">
                                            <h5 class=" mb-0 fw-500 mb-2">Whacenter Web Whatsapp</h5>
                                        </div>
                                    </div>
                                    <!-- Chat Options -->
                                    <ul class="nav flex-nowrap">
                                        <li class="nav-item list-inline-item mr-0">
                                            <div class="dropdown">
                                                <a href="#" role="button" title="Details" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span data-feather="more-vertical"></span>
                                                </a>

                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a class="dropdown-item align-items-center d-flex" href="#"
                                                        data-chat-info-toggle="">
                                                        <!-- Default :: Inline SVG -->
                                                        <span data-feather="users"></span>
                                                        <span>Create new group</span>
                                                    </a>

                                                    <a class="dropdown-item align-items-center d-flex" href="#">
                                                        <!-- Default :: Inline SVG -->
                                                        <span data-feather="trash-2"></span>

                                                        <span>Delete conversation</span>
                                                    </a>
                                                    <a class="dropdown-item align-items-center d-flex" href="#">
                                                        <!-- Default :: Inline SVG -->
                                                        <span data-feather="x-octagon"></span>

                                                        <span>Block & report</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div class="chat-box p-xl-30 pl-lg-20 pr-lg-0">
                                    <ul class="chat-list">
                                        <li class="chat-item">
                                            <div class="chat-img">
                                                <img src="@/assets/img/author/1.jpg" alt="user">
                                            </div>
                                            <div class="chat-content">
                                                <h6 class="font-medium">Admin</h6>
                                                <div class="box bg-light-info">Sekarang, Anda dapat mengirim dan
                                                    menerima pesan tanpa perlu menghubungkan telepon Anda ke Internet.
                                                </div>
                                            </div>
                                            <div class="chat-time">10:56 am</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- Ends: .tab-content -->
            </div><!-- Ends: .chat-area -->
        </div>

        <modal name="insert_order" :height="'auto'" :adaptive="true" :scrollable="true">
            <div style="margin: 20px">
                <form v-on:submit.prevent="insertOrderForm">
                    <div v-for="(v, key) in orderForm" :key="key">
                        <label>{{ v.key }}</label>
                        <input type="text" class="form-control" v-model="v.value" required />
                        <br>
                    </div>
                    <div class="button-group d-flex pt-25">
                        <button type="submit"
                            class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                            simpan
                        </button>
                    </div>
                </form>
            </div>
        </modal>
        <modal class="container" name="cek_ongkir" :width="'70%'" :height="'auto'" :adaptive="true" :scrollable="true">

            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Cek Ongkir</h5>
                </div>
                <div class="card-body ">

                    <form id="frmHitungOngkir" v-on:submit.prevent="hitungOngkir">
                        <div class="d-flex">
                            <div class="form-group">
                                <label for="toko">Daerah Asal</label>
                                <select class="form-control" name="origin" id="toko">
                                    <option v-for="data in listToko" v-bind:key="data.id" v-bind:value="data.id">{{
                                        data.label}}</option>
                                </select>
                            </div>
                        </div>
                        Daerah Tujuan

                        <div class="row mb-2">
                            <div class="col-sm-4">
                                <div v-if="isProvinceLoading">
                                    <div class="spinner-border spinner" role="status"></div>
                                    Loading
                                </div>

                                <multiselect v-else v-model="province" tag-placeholder="Pilih Provinsi"
                                    placeholder="Cari Provinsi" label="province" track-by="province_id"
                                    :options="provinces" :multiple="false" :show-labels="false">
                                </multiselect>


                            </div>
                            <div class="col-sm-4">
                                <div v-if="isCityLoading">
                                    <div class="spinner-border spinner" role="status"></div>
                                    Loading
                                </div>
                                <multiselect v-else v-model="city" tag-placeholder="Pilih Kota" placeholder="Cari Kota"
                                    label="label" track-by="city_id" :options="cities" :multiple="false"
                                    :show-labels="false">
                                </multiselect>
                            </div>
                            <div class="col-sm-4">
                                <div v-if="isSubdistrictLoading">
                                    <div class="spinner-border spinner" role="status"></div>
                                    Loading
                                </div>
                                <multiselect v-else v-model="kec_tujuan" tag-placeholder="Pilih Kecamatan"
                                    placeholder="Cari Kecamatan" label="subdistrict_name" track-by="subdistrict_id"
                                    :options="subdistricts" :multiple="false" :show-labels="false">
                                </multiselect>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="input-group mb-2">
                                    <input type="text" name="weight" id="berat" class="form-control"
                                        placeholder="Berat">
                                    <div class="input-group-append">
                                        <div class="input-group-text">Gram</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <select class="form-control" name="courier" id="kurir">
                                    <option value="">Pilih Kurir</option>
                                    <option v-for="data in dataKurir" v-bind:key="data.idx" :value="data.idx">{{
                                        data.nama }}</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <button type="submit" class="btn btn-success">CEK ONGKIR</button>
                            </div>

                        </div>





                    </form>


                </div>
                <div class="container">
                    <table class="table">

                        <tr v-for="(rk, indeks) in ongkir" :key="indeks">
                            <td class="w-50 align-middle">
                                <b>{{ kodeKurir }}</b><br />
                                Layanan : {{ rk.service }}<br />
                                Deskripsi : {{ rk.description }}
                                <p>Estimasi : {{ rk.cost[0].etd }} hari</p>
                            </td>
                            <td class="w-25 align-middle"><b>{{ rk.cost[0].value.toLocaleString('id-ID', {
                                style:
                                    'currency', currency: 'IDR', maximumFractionDigits: 0 })}}</b></td>
                            <td class="w-25 align-middle justify-content-end"><button class="btn"><i
                                        class="fa-solid fa-copy" @click="copyOngkir(indeks)"></i></button></td>
                        </tr>
                    </table>
                </div>




                <div class="card-footer d-flex flex-row-reverse"><button class="btn btn-primary"
                        @click="$modal.hide('cek_ongkir')">Tutup</button></div>
            </div>


        </modal>

    </div>
</template>

<script>
import moment from 'moment'
import CustomText from "@/components/module/CustomText.vue";
import Multiselect from 'vue-multiselect'

export default {
    components: {
        CustomText, Multiselect
    },
    data() {
        return {
            deviceID: 0,
            chatLists: [],
            contacts: [],
            isSelected: [],
            currentChat: null,
            chats: [],
            fn: {},
            message: "",
            isChatListLoading: true,
            isChatLoading: false,
            isSendable: false,
            tabActive: "A",
            autotext: [],
            bottomCSS: "105px",
            hover: {},
            orderForm: {},
            category: [],
            selectedCategory: "",
            file: null,
            provinces: [],
            cities: [],
            province: "",
            city: "",
            kec_tujuan: "",
            subdistricts: [],
            isProvinceLoading: false,
            isCityLoading: false,
            isSubdistrictLoading: false,
            dataKurir: [{ idx: "pos", nama: "POS Indonesia (POS)" },
            { idx: "lion", nama: "Lion Parcel (LION)" },
            { idx: "jne", nama: "Jalur Nugraha Ekakurir (JNE)" },
            { idx: "ninja", nama: "Ninja Xpress (NINJA)" },
            { idx: "ide", nama: "ID Express (IDE)" },
            { idx: "sicepat", nama: "SiCepat Express (SICEPAT)" },
            { idx: "sap", nama: "SAP Express (SAP)" },
            { idx: "ncs", nama: "Nusantara Card Semesta (NCS)" },
            { idx: "anteraja", nama: "AnterAja (ANTERAJA)" },


            ],
            listToko: [],
            ongkir: 0,
            kodeKurir: '',
            respOngkir: ''
        };
    },
    beforeDestroy() {
        this.$root.$off("ws", this.fn);
    },
    watch: {
        '$store.state.customer_service.chats': function () {
            // DOM not updated yet

            this.$nextTick(function () {
                this.scrollToElement()
            });
        },
        message: {
            handler(val) {
                this.autotext = []
                if (val.startsWith("/")) {
                    this.autotextSearch(val)
                }
            }
        },
        province: {
            handler(val, old) {
                if (val?.province_id && val?.province_id != old) {
                    this.loadCityData(val?.province_id)
                    this.city = null
                    // this.form.origin = null
                    this.cities = []
                    this.subdistricts = []
                }
            },
            deep: true
        },
        city: {
            handler(val, old) {
                if (val?.city_id && val?.city_id != old) {
                    this.loadSubdistrictData(val?.city_id)
                    //this.form.origin = null
                    this.subdistricts = []
                }
            },
            deep: true
        },
    },
    async mounted() {
        this.deviceID = this.$route.params.id

        this.fn = async (param) => {
            if (param == "pong") return
            param = JSON.parse(param)
            // console.log('param', param)
            if (param.event == "incoming") {
                param.data.message.number = param.data.message.from;
                // console.log('param', param)
                if (param.data.message.device_id == this.deviceID) {
                    //CHECK ACTIVE CHAT LIST
                    let activeChatLists = this.$store.state.customer_service.active_chat_lists ?? []
                    let chatLists
                    let flag = false

                    let find = await activeChatLists?.findIndex(a => a.number == param.data.message.number)

                    if (find != -1) {
                        chatLists = activeChatLists
                        //PUSH TO ACTIVE
                    } else {
                        chatLists = this.$store.state.customer_service.pending_chat_lists ?? []
                        //PUSH TO PENDING
                    }

                    let newChat = {
                        number: param.data.message.number,
                        last_chat: {
                            text: param.data.message.message,
                            file: param.data.message.media,
                            from_me: param.data.message.is_me
                        },
                        last_update: param.data.message.updated_at,
                        notification: 1,
                        name: param.data.message.name
                    }

                    // console.log('newChat', newChat)

                    for (let i = 0; i < chatLists.length; i++) {
                        if (chatLists[i].number == param.data.message.number) {
                            newChat.notification = chatLists[i].notification + 1
                            chatLists.splice(i, 1)
                            flag = true
                        }
                    }

                    if (param.data.message.number == this.currentChat?.number) {
                        let chats = this.$store.state.customer_service.chats
                        // console.log('chats', chats)
                        // console.log('param', param.data)
                        chats.push(param.data.message)
                        this.$store.commit("PUSH_HISTORY_CHATS_CS", chats)

                        newChat.notification = 0
                    }

                    let resp = true
                    if (flag == false) {
                        // console.log('newChat', newChat)

                        resp = await this.validateSession(newChat.number)
                    }

                    if (resp) {
                        chatLists = [newChat, ...chatLists]

                        if (find != -1) {
                            this.$store.commit("PUSH_HISTORY_CHAT_LISTS_CS_ACTIVE", chatLists)
                        } else {
                            this.$store.commit("PUSH_HISTORY_CHAT_LISTS_CS_PENDING", chatLists)
                        }
                    }
                }
            } else if (param.event == "refresh") {
                if (param.data.refresh == "PENDING_CHAT_LIST") {
                    await this.getPendingChatLists()
                    await this.getActiveChatLists()

                    if (param.data.number == this.currentChat?.number) {
                        await this.resetChat()
                    }
                }
            }
        }
        this.$root.$on("ws", this.fn);

        // this.getCategory()
        // await this.getContact()
        await this.getPendingChatLists()
        await this.getActiveChatLists()
    },
    methods: {
        copyOngkir(idx) {
            let hasil = 'Asal: ' + this.respOngkir.origin_details.subdistrict_name + ',' + this.respOngkir.origin_details.city + ', ' + this.respOngkir.origin_details.province
            hasil += '\nTujuan: ' + this.respOngkir.destination_details.subdistrict_name + ',' + this.respOngkir.destination_details.city + ', ' + this.respOngkir.destination_details.province
            hasil += '\nBerat: ' + this.respOngkir.query.weight + ' gram'
            hasil += '\nKurir: ' + this.respOngkir.results[0].code.toUpperCase()
            hasil += '\nLayanan: ' + this.respOngkir.results[0].costs[idx].service + '(' + this.respOngkir.results[0].costs[idx].description + ')'
            hasil += '\nEstimasi: ' + this.respOngkir.results[0].costs[idx].cost[0].etd + ' hari'
            hasil += '\nHarga: ' + this.respOngkir.results[0].costs[idx].cost[0].value
            hasil += '\nCatatan: ' + this.respOngkir.results[0].costs[idx].cost[0].note

            navigator.clipboard.writeText(hasil);
        },
        async hitungOngkir() {
            let frmHitungOngkir = document.getElementById('frmHitungOngkir');
            let formData = new FormData(frmHitungOngkir)
            var hasil = "{";
            let nilai = ''
            for (let [key, value] of formData) {
                if (value.length == 0 || value == 0) {
                    alert("nilai " + key + " harus diisi ")
                    return
                }
                if (key == 'origin') {
                    nilai = value.split("-")
                    value = nilai[2]

                }

                hasil += `"${key}": "${value}",`;

            }

            hasil += `"destination": "${this.kec_tujuan.subdistrict_id}"`

            hasil += "}"

            let ongkos = await this.$store.dispatch("ambilOngkir", JSON.parse(hasil))
            this.respOngkir = ongkos.data.data.ongkir.rajaongkir
            this.ongkir = ongkos.data.data.ongkir.rajaongkir.results[0].costs
            this.kodeKurir = ongkos.data.data.ongkir.rajaongkir.results[0].code.toUpperCase()
            // console.log(ongkos.data.data.ongkir.rajaongkir)
        },
        async getCategory() {
            let cat = await this.$store.dispatch("category_all", {})
            this.category = cat.data.data.categories
        },
        async chatChangeCategory() {
            await this.$store.dispatch("customer_service_update_category", {
                id: parseInt(this.currentChat.id),
                category: this.currentChat.category,
            });

            // console.log(this.currentChat)
            // alert(this.currentChat.category)
        },
        async changeChatCategory(category) {
            this.selectedCategory = category
            await this.getPendingChatLists(category)
            await this.getActiveChatLists(category)
        },
        getCategoryButtonClass(category) {
            if (this.selectedCategory == category) {
                return "btn btn-info btn-sm"
            } else {
                return "btn btn-outline-info btn-sm"
            }
        },
        async insertOrderForm() {
            try {
                let data = {
                    number: this.currentChat.number,
                    deviceID: this.deviceID,
                    data: this.orderForm
                }

                let response = await this.$store.dispatch('order_create', data)

                this.$toast.success(response.data.message);
                this.$root.$emit("toggle_modal", "insert_order")
            } catch (err) {
                this.$root.$emit("toggle_modal", "insert_order")
                this.$toast.error(err.response.data.message);
            }
        },
        insertOrder(idx) {
            this.orderForm = []

            let msg = this.$store.state.customer_service.chats[idx]
            const textLine = msg?.text?.split(/\r?\n/);
            textLine.forEach((text) => {
                const data = this.getData(text);
                if (data) {
                    this.orderForm.push({
                        key: data[0],
                        value: data[1]
                    })
                }
            });

            if (this.orderForm.length == 0) {
                this.$toast.error("Tidak bisa parsing pesan")
            } else {
                this.$modal.toggle("insert_order");
            }
        },
        async ambilToko() {
            try {
                let toko = await this.$store.dispatch("ambil_toko")
                this.listToko = []
                this.listToko.push({
                    id: 0,
                    label: "Pilih Toko"
                })
                for (let i = 0; i < toko.data.data.origins.length; i++) {
                    let element = toko.data.data.origins[i];
                    this.listToko.push({
                        id: element.origin,
                        label: element.name
                    })
                }
            } catch (err) {
                this.$toast.error(err.response.data.message)
            }
        },
        async tampilCekOngkir() {
            this.$modal.toggle("cek_ongkir");
            this.ambilToko()
            try {
                let detail = await this.$store.dispatch("rajaongkir_province")
                this.provinces = detail.data.data.provinces
                //console.log(this.provinces)
            } catch (err) {
                this.$toast.error(err.response.data.message)
            }


        },

        async loadCityData(id) {
            this.isCityLoading = true
            try {
                this.cities = []

                let detail = await this.$store.dispatch("rajaongkir_city", {
                    id
                })

                for (let i = 0; i < detail.data.data.cities.length; i++) {
                    const element = detail.data.data.cities[i];
                    this.cities.push({
                        city_id: element.city_id,
                        label: element.type + " " + element.city_name
                    })
                }
            } catch (err) {
                this.$toast.error(err.response.data.message)
            }
            this.isCityLoading = false
        },
        async loadSubdistrictData(id) {
            this.isSubdistrictLoading = true
            try {
                this.subdistricts = []

                let detail = await this.$store.dispatch("rajaongkir_subdistrict", {
                    id
                })
                this.subdistricts = detail.data.data.subdistricts
            } catch (err) {
                this.$toast.error(err.response.data.message)
            }
            this.isSubdistrictLoading = false
        },
        getData(text) {
            if (!text.includes(":")) return null;
            const label = text.substr(0, text.indexOf(":")).trim();
            const value = text.substr(text.indexOf(":") + 1).trim();
            return [label, value];
        },
        formatDateRelative(unix) {
            return moment.utc(unix).local().fromNow()
        },
        formatDateDetail(unix) {
            return moment(unix).format("LLL")
        },
        async getContact() {
            try {
                let res = await this.$store.dispatch("contact_load_from_device", {
                    id: this.deviceID
                });

                this.$store.commit("PUSH_HISTORY_CONTACTS", res.data.data.contacts)
            } catch (err) {
                console.log(err)
            }

        },
        getName(number1) {
            // console.log('get name', number1, number2)
            // const list = this.$store.state.customer_service.active_chat_lists;
            // const find = list.find((x) => x.number = number1);
            // if (find) {
            //     return find.name;
            // }

            // const listPending = this.$store.state.customer_service.pending_chat_lists
            // const findPending = listPending.find((x) => x.number = number1);
            // if (findPending) {
            //     return findPending.name;
            // }
            
            return number1;
            // let contacts = this.$store.state.history.contacts
            // for (let i = 0; i < contacts.length; i++) {
            //     let formattedNumber = this.formatNumber(contacts[i].number, contacts[i].is_group)
            //     if(formattedNumber == number){
            //         if(contacts[i].name != ""){
            //             return contacts[i].name
            //         }else{
            //             return contacts[i].number
            //         }
            //     }
            // }

            // return number
        },
        async getPendingChatLists(category = "") {
            this.isChatListLoading = true
            let res = await this.$store.dispatch("customer_service_pending_chat_lists", {
                id: this.deviceID,
                category
            });
            
            this.chatLists = res.data.data.chat_lists
            this.$store.commit("PUSH_HISTORY_CHAT_LISTS_CS_PENDING", this.chatLists)

            this.isChatListLoading = false
        },
        async getActiveChatLists(category = "") {
            // this.isChatListLoading = true
            let res = await this.$store.dispatch("customer_service_active_chat_lists", {
                id: this.deviceID,
                category
            });
            //this.chatLists = res.data.data.chat_lists
            this.$store.commit("PUSH_HISTORY_CHAT_LISTS_CS_ACTIVE", res.data.data.chat_lists)

            this.isChatListLoading = false
        },
        async getChats(number) {
            let res = await this.$store.dispatch("customer_service_chats", {
                id: this.deviceID,
                number: number
            });

            return res.data.data.chats
        },
        updateFile(e) {

            this.file = e;

        },
        async sendMessage() {
            if (this.message == "") {
                return
            }

            let text = this.message
            let number = this.currentChat.number
            this.message = ""

            let url = ""
            if (this.file) {
                let formData = new FormData();
                formData.append('file', this.file)
                let response = await this.$store.dispatch('cdn_upload', formData)
                url = response.data.data.url
            }

            let finalMessage = {
                device_id: this.deviceID,
                schedule: moment().utc().valueOf(),
                broadcast: [{
                    message_type: "text",
                    to: number,
                    text: text,
                    file: url,
                    delay: 0,
                }]
            }

            try {
                await this.$store.dispatch('cs_outgoing_send', finalMessage)

                let chats = this.$store.state.customer_service.chats
                // console.log('chats', chats)
                chats.push({
                    device_id: this.deviceID,
                    from_me: 1,
                    number: this.currentChat.number,
                    message: text,
                    created_at: finalMessage.schedule,
                    updated_at: finalMessage.schedule
                })
                this.$store.commit("PUSH_HISTORY_CHATS_CS", chats)

                let activeChatLists = this.$store.state.customer_service.active_chat_lists ?? []

                let lastChatIndex = activeChatLists.findIndex(a => a.number == this.currentChat.number)
                activeChatLists[lastChatIndex].last_chat.text = text
                activeChatLists[lastChatIndex].last_update = finalMessage.schedule

                this.$store.commit("PUSH_HISTORY_CHAT_LISTS_CS_ACTIVE", activeChatLists)

            } catch (err) {
                this.$toast.error("Error to sending message")
            }

        },
        formatNumber(number, is_group) {
            if (number.charAt(0) == "0") {
                number = "62" + number.substring(1)
            }

            if (is_group) {
                number = number + "@g.us"
            } else {
                number = number + "@s.whatsapp.net"
            }

            return number
        },
        resetChat() {
            this.isSendable = false
            this.currentChat = null
            this.$store.commit("PUSH_HISTORY_CHATS_CS", [])
            this.isChatLoading = false
            this.isSelected = []
        },
        async openChat(name, number, sendable) {
            // console.log('open chat', number, sendable)
            if (this.currentChat?.number == number) return

            this.isSendable = sendable
            this.isChatLoading = true

            this.select(number)
            this.currentChat = {}

            let chatLists = []
            if (this.tabActive == 'A') {
                chatLists = this.$store.state.customer_service.pending_chat_lists ?? []
            } else {
                chatLists = this.$store.state.customer_service.active_chat_lists ?? []
            }
            this.currentChat = chatLists.find(a => a.number == number)

            // this.currentChat.name = this.getName(this.currentChat.number, this.currentChat.number)
            this.currentChat.name = name
            let chats = await this.getChats(number)
            this.$store.commit("PUSH_HISTORY_CHATS_CS", chats)
            //this.chats = chats

            let lastChatIndex = chatLists.findIndex(a => a.number == number)
            chatLists[lastChatIndex].notification = 0

            if (this.tabActive == 'A') {
                this.$store.commit("PUSH_HISTORY_CHAT_LISTS_CS_PENDING", chatLists)
            } else {
                this.$store.commit("PUSH_HISTORY_CHAT_LISTS_CS_ACTIVE", chatLists)
            }

            this.isChatLoading = false
        },
        async receiveChat(number) {
            try {
                await this.$store.dispatch("customer_service_receive_chats", {
                    id: this.deviceID,
                    number: number
                });

                this.$toast.success("Berhasil terima chat")
            } catch (err) {
                this.$toast.error(err.response.data.message)
            }
        },
        async closeCurrentSession() {
            try {
                await this.$store.dispatch("customer_service_close_chats", {
                    id: this.deviceID,
                    number: this.currentChat.number
                });
                this.resetChat()
                this.$toast.success("Berhasil tutup session chat")
            } catch (err) {
                this.$toast.error(err.response.data.message)
            }
        },
        async validateSession(number) {
            try {
                await this.$store.dispatch("customer_service_validate", {
                    id: this.deviceID,
                    number: number
                });

                return true
            } catch (err) {
                return false
            }
        },
        async autotextSearch(q) {
            this.autotext = []

            try {
                q = q.substring(1);
                let search = await this.$store.dispatch('autotext_search', q)
                let start = 105
                for (let i = 0; i < search.data.data.autotexts.length; i++) {
                    const element = search.data.data.autotexts[i]
                    this.autotext.push(element.autotext)

                    start += 65
                }

                start -= 65

                this.bottomCSS = start + "px"
            } catch (err) {
                this.$toast.error(err.response.data.message);
            }
        },
        select(id) {
            this.isSelected = []
            if (this.isSelected.includes(id)) {
                this.isSelected = this.isSelected.filter(s => s !== id)
            } else this.isSelected.push(id)

        },
        trimString(string, length) {
            if (string == null || string == "") {
                return ""
            }
            return string.length > length ? string.substring(0, length) + '...' : string;
        },
        scrollToElement() {
            var container = this.$el.querySelector(".chat-box");
            container.scrollTop = container.scrollHeight;
        },
        changeTab(tab) {
            this.tabActive = tab
        },
        countChatLists(obj) {
            try {
                return obj.length
            } catch (err) {
                return 0
            }
        },
        hoverChat(idx) {
            this.hover = {
                idx,
            }
        },
        unhoverChat() {
            this.hover = {
                idx: null
            }
        },
        getHoverChat(idx) {
            if (this.hover.idx == idx) {
                return true
            } else {
                return false
            }
        },

    },
};
</script>

<style scoped>
.chatactive {
    background-color: #e4e6ea;
}

.chat-type-dropdown {
    color: white;
    background-color: #29434ea5;
    height: 50px;
    border-radius: 5px;
    padding: 0 25px;
    transition: all 0.3s ease;
    margin-right: 8px;
    margin-bottom: 5px;
}

.search-item {
    position: absolute;
    left: 0;
    right: 0;
    height: 50px;
    margin-left: 40px;
    margin-right: 110px;
}

.chat-sidebar {
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 10px;
    height: 100%;
}

.chat-header {
    padding: 20px 30px;
    border-bottom: 1px solid #e9ecef;
}

.chat-footer {
    height: 90px;
}

.chat-body {
    border: 1px solid #e9ecef;

    position: relative;
    display: flex;
    flex-direction: column;
}

.chat-box {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
</style>
