<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    Order
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <a v-on:click="toggleModal('message_setting')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-envelope fs-16"></i>
                                Setting Pesan
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <Table />
                    </div>
                </div>
            </div>
            <modal name="message_setting" :height="'auto'" :adaptive="true" :scrollable="true" style="overflow-y: auto;">
                <div style="margin: 20px">
                    <Add/>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import Table from "@/components/tables/customer-service/order/Table.vue";
    import Add from "@/components/forms/customer-service/order/Message.vue";

    export default {
        components: {
            Table,
            Add,
        },
        data() {
            return {
                cs: [],
                exportJson: [],
                chatWidgetQuota: 0,
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
        },
    };
</script>