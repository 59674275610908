<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">
                                        <router-link to="/cs-page/order">Order</router-link> / Follow Up
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading"
                            class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <!-- <label for="">Variabel Default Nomor Telepon</label>
                                <input type="text" class="form-control" v-model="form.DefaultNumber" required>
                                <br> -->
                            <div class="row">
                                <div class="col-md-12">
                                    <center><h4><b>DATA</b></h4></center>
                                    <br>
                                    <table style="border-bottom: none;">
                                        <tr>
                                            <td class="noBorder"><b>Nomor</b></td>
                                            <td class="noBorder"><b>:</b></td>
                                            <td class="noBorder"><b>{{ form.number }}</b></td>
                                        </tr>
                                        <tr v-for="val in form.data" v-bind:key="val.key">
                                            <td class="noBorder">{{ val.key }}</td>
                                            <td class="noBorder">:</td>
                                            <td class="noBorder">{{ val.value }}</td>
                                        </tr>
                                    </table>
                                    <hr>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Follow Up 1</label>
                                    <CustomTextArea v-model="message.FollowUp1" pos="bottom"></CustomTextArea>
                                    <br>
                                    <button class="btn btn-success btn-block" v-on:click="sendFollowUp(form.id, message.FollowUp1)">Kirim</button>
                                    <br>
                                    <label for="">Follow Up 2</label>
                                    <CustomTextArea v-model="message.FollowUp2" pos="bottom"></CustomTextArea>
                                    <br>
                                    <button class="btn btn-success btn-block">Kirim</button>
                                    <br>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Follow Up 3</label>
                                    <CustomTextArea v-model="message.FollowUp3" pos="bottom"></CustomTextArea>
                                    <br>
                                    <button class="btn btn-success btn-block">Kirim</button>
                                    <br>
                                    <label for="">Follow Up 4</label>
                                    <CustomTextArea v-model="message.FollowUp4" pos="bottom"></CustomTextArea>
                                    <br>
                                    <button class="btn btn-success btn-block">Kirim</button>
                                    <br>
                                </div>
                            </div>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomTextArea from "@/components/module/CustomTextarea.vue";

    export default {
        components: {
            CustomTextArea
        },
        data() {
            return {
                id: 0,
                form: {},
                message: {},
                isLoading: true
            };
        },
        mounted() {
            this.id = this.$route.query.id
            this.loadData()
            this.loadMessage()
        },
        methods: {
            async loadData() {
                try {
                    let detail = await this.$store.dispatch("order_detail", {
                        id: this.id
                    })
                    this.form = detail.data.data.order
                    this.form.data = JSON.parse(this.form.data)
                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/cs-page/order")
                }
            },
            async loadMessage() {
                try {
                    let detail = await this.$store.dispatch("order_message")
                    this.message = detail.data.data.message
                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/cs-page/order")
                }
            },
            async sendFollowUp(id, message) {
                try {
                    let response = await this.$store.dispatch('order_send_follow_up', {
                        id,
                        message
                    })
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
        },
    };
</script>