<template>
    <div>
        <center>
            <h4>Setting Pesan Default</h4>
        </center>
        <br>
        <form v-on:submit.prevent="save" enctype="multipart/form-data">
            <!-- <label for="">Variabel Default Nomor Telepon</label>
            <input type="text" class="form-control" v-model="form.DefaultNumber" required>
            <br> -->
            <label for="">Welcome Message</label>
            <CustomTextArea v-model="form.WelcomeMessage" pos="bottom"></CustomTextArea>
            <br>
            <label for="">Process Message</label>
            <CustomTextArea v-model="form.Process" pos="bottom"></CustomTextArea>
            <br>
            <label for="">Complete Message</label>
            <CustomTextArea v-model="form.Complete" pos="bottom"></CustomTextArea>
            <br>
            <label for="">Follow Up 1</label>
            <CustomTextArea v-model="form.FollowUp1" pos="bottom"></CustomTextArea>
            <br>
            <label for="">Follow Up 2</label>
            <CustomTextArea v-model="form.FollowUp2" pos="bottom"></CustomTextArea>
            <br>
            <label for="">Follow Up 3</label>
            <CustomTextArea v-model="form.FollowUp3" pos="bottom"></CustomTextArea>
            <br>
            <label for="">Follow Up 4</label>
            <CustomTextArea v-model="form.FollowUp4" pos=""></CustomTextArea>
            <br>

            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>

<script>
    import CustomTextArea from "@/components/module/CustomTextarea.vue";

    export default {
        components: {
            CustomTextArea
        },
        data() {
            return {
                form: {
                    name: ""
                },
            };
        },
        mounted() {
            this.$root.$on("toggle_modal", (param) => {
                this.toggleModal(param);
            });

            this.loadData()
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async loadData(){
                try{
                    let detail = await this.$store.dispatch("order_message")
                    this.form = detail.data.data.message
                    // console.log(this.form)
                    this.isLoading = false
                }catch(err){
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/cs-page/order")
                }
            },
            async save(){
                try{
                    let s = await this.$store.dispatch('order_message_update', this.form)
                    this.$toast.success(s.data.message)
                    this.$root.$emit("toggle_modal", "new_category")
                    this.$root.$emit("refresh_table", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    

    hr{
        margin-top: 15px;
        margin-bottom: 15px;
    }
</style>