<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Customer Service</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div v-if="isLoading" class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                        <div v-else class="global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <img :src="form.parsed_avatar" alt="" width="200px" class="rounded">
                            </center>
                            <br>
                            <form v-on:submit.prevent="editCS" enctype="multipart/form-data">
                                <label for="">Email</label>
                                <input type="email" class="form-control" v-model="form.email" required>
                                <br>
                                <label for="">Password</label>
                                <input type="text" class="form-control" v-model="form.password">
                                <p>*Kosongkan jika tidak ingin mengganti password</p>
                                <br>
                                <label for="">Device</label>
                                <multiselect v-model="form.device" tag-placeholder="Pilih device"
                                placeholder="Pilih device" label="name" track-by="select_key" :options="device"
                                :multiple="true"></multiselect>
                                <!-- <select class="form-control" v-model="form.device" multiple>
                                    <option v-for="(d, idx) in device" v-bind:key="idx" :value='d.device_id' :selected="form.device == d.device_id">
                                        {{ d.name + " (" + d.status + ")"}}
                                    </option>
                                </select> -->
                                <br>
                                <button class="btn btn-success btn-block">Simpan</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    //import moment from 'moment'
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                form: {
                    avatar: "",
                    name: "",
                    role: "",
                    phone: "",
                    email: "",
                    password: "",
                    device: [],
                    old_avatar: "",
                    parsed_avatar: "",
                },
                device: [],
                deviceArray: [],
                isLoading: true
            };
        },
        async mounted() {
            await this.getDevice({ status: "all" })
            await this.getCS({id: this.$route.query.id})
        },
        methods: {
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                let device = res.data.data.devices

                for (let i = 0; i < device.length; i++) {
                    device[i].select_key = device[i].device_id + "-" + device[i].name + "-" + device[i].number
                }

                this.device = device

                // console.log(this.device)
            },
            async getCS(param) {
                let res = await this.$store.dispatch("customer_service_detail", param);
                res = res.data.data.customer_service
                this.form = {
                    avatar: "",
                    name: res.name,
                    role: res.role,
                    phone: res.phone,
                    email: res.email,
                    password: res.password,
                    device: [],
                    old_avatar: res.avatar ?? "",
                    parsed_avatar: "",
                }

                //DEVICE  
                for (let i = 0; i < res.device_array.length; i++) {
                    let device_array = res.device_array[i].split("-")

                    let find = this.device.find(x => x.device_id == device_array[0])
                    if(find){
                        this.form.device.push(find)
                    }
                }
                // this.form.device = res.device_array

                // console.log(this.form.device)

                this.isLoading = false
            },
            async editCS(){
                try{
                    let device = []

                    for (let i = 0; i < this.form.device.length; i++) {
                        device.push(this.form.device[i].device_id + '-' + this.form.device[i].name + '-' + this.form.device[i].number) 
                    }

                    let data = {
                        id: this.$route.query.id,
                        email: this.form.email,
                        password: this.form.password,
                        device: device,
                    }

                    let update_cs = await this.$store.dispatch('customer_service_update', data)
                    this.$toast.success(update_cs.data.message)
                    this.getCS({id: this.$route.query.id})
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            isURL(value){
                var regexp = /^(ftp|http|https|chrome|:\/\/|\.|@){2,}(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\S*:\w*@)*([a-zA-Z]|(\d{1,3}|\.){7}){1,}(\w|\.{2,}|\.[a-zA-Z]{2,3}|\/|\?|&|:\d|@|=|\/|\(.*\)|#|-|%)*$/gum
                return regexp.test(value);
            }
        },
    };
</script>

<style scoped>
    
</style>